<div class="w90vw h90vh fxColumn px-4 py-2 gap24">
    <div class="fxRow fxRowSpaceBetween">
        <h3 class="w30p noSelect">{{ (this.dialog.options?.data ? 'Edit Recipe' : 'Add Recipe')| translate }}</h3>
        <div class="w70p">
            <form class="form_search rounded-1 w450">
                <input #searchInput  id="query search-autocomplete"
                       name="search-product"
                       [placeholder]="'header_search_hint'| translate" class="nav-search nav-search-field"
                       [(ngModel)]="searchWord"
                       (input)="onSearch()">
                <button type="submit" class="btn-search">
                    <i class="ti-search"></i>
                </button>
            </form>
        </div>
    </div>
    <div class="fadeIn fxColumn" *ngIf="isLoaded">
        <!-- product listing section start -->
        <div class="fxRow gap12" style="height: calc(100vh - 212px);">
            <div class="col-xl-auto">
                <app-side-menu [items]="categoryList"></app-side-menu>
            </div>
            <div *ngIf="isLoadedProductList"
                 infiniteScroll
                 [infiniteScrollUpDistance]="2"
                 [infiniteScrollThrottle]="50"
                 [scrollWindow]="false"
                 (scrolled)="getMoreProductList()"
                 class="col-xl-auto add-recipe-main h-no-header tab-content scrollY"
            >
                <div class="tab-pane fade show active px-3" id="grocery">
                    <div id="categoryScrollId"></div>
                    <div *ngIf="categoryItemList.length > 0">
                        <ng-container *ngFor="let categoryItem of categoryItemList; let i = index;">
                            <div *ngIf="!loadingProduct && categoryItem.itemList && categoryItem.itemList[0]">
                                <div class="title8 mt24">
                                    <div class="fxRow fxRowSpaceBetween fxColumnCenter">
                                        <div class="fxColumn">
                                            <h2>{{ categoryItem?.label || '-' }}</h2>
                                            <p *ngIf="categoryItem.description">{{ categoryItem.description }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-sm-4 g-3">
                                    <app-product-select
                                            *ngFor="let product of categoryItem.itemList"
                                            [product]="product"
                                            [loader]="loadingProduct"
                                            (eItemClick)="onClickItem($event)"
                                            [isSelected]="isItemSelected(product)"
                                            class = "w25p"
                                    ></app-product-select>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="categoryItemList.length <= 0" class="h450 fullWidth fxRow fxRowCenter fxColumnCenter">
                        <div>
                            <div class="fxRow fxRowCenter textGray font18 noSelect" style="padding-bottom: 64px">{{'No Product'}}</div>
                            <button  class="btn btn-solid" (click)="onCustomProduct()">
                                {{ 'Add Custom ingredient' | translate }}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf="!isLoadedProductList" class="col-xl-auto add-recipe-main h-no-header tab-content fxRow fxColumnCenter fxRowCenter">
                <img class="loading-icon" src="./assets/image/img/product/loader.gif" >
            </div>
            <div class="add-recipe-list fullHeight fxColumn">
                <div class="mb-3">
                    <label for="form-input-name" class="form-label">{{ 'Recipe name' | translate }}</label>
                    <input id="form-input-name"
                           [(ngModel)]="recipe.name"
                           [class.is-invalid]="error.name"
                           (input)="onInputChange('name')"
                           class="form-control"
                           [placeholder]="'Recipe name' | translate"
                    />
                </div>
                <div class="mb-4 fxColumn backgroundOver p12 borderRadius" style="background-color: #f8f8f8; height: calc(100% - 350px)">
                    <div class="fxRow fxColumnCenter">
                        <label for="form-input-subject" class="form-label">{{ 'Ingredients' | translate }}</label>
                        <label *ngIf="error.materialList" for="form-input-subject" class="form-label colorDanger ml12 font12">{{ 'Please add at least one ingredient' | translate }}</label>
                    </div>
                    <div *ngIf="!isHaveProduct()" class="fullHeight fxRow fxRowCenter fxColumnCenter">{{'No Data'}}</div>
                    <div *ngIf="isHaveProduct()" class="fullHeight scrollY borderRadius p12" [class.borderDanger]="error.materialList" [class.h222]="isHaveCustomProduct()">
                        <div *ngFor="let material of recipe.materialList; let index = index;">
                            <div *ngIf="!material.itemList[0]?.isNeedValidate" class="fxRow fxColumnCenter fxRowSpaceBetween py6 borderRadius mb12" style="gap: 12px">
                                <div  class="fontSemiBold w40p">{{material.itemList[0].name}}</div>
                                <input [(ngModel)]="material.itemList[0].qty" type="number" min="1" pattern="^[0-9]" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                                       [class.is-invalid]="errorMaterialItem[index] && errorMaterialItem[index].qty" placeholder="Amount" (input)="onInput(index ,'qty')"
                                       class="form-control w25p"
                                />
                                <select [(ngModel)]="material.itemList[0].unitOfMeasurement" [class.is-invalid]="errorMaterialItem[index] && errorMaterialItem[index].measure"
                                        (change)="onInput(index ,'measure')" class="form-control w30p" style="appearance: auto !important;">
                                    <option *ngFor="let uom of getItemMeasure(material.itemList[0].unitOfMeasurement)" [value]="uom">{{uom}}</option>
                                </select>
                                <div class="w10p fxRow fxRowCenter fxColumnCenter">
                                    <div class="cursorPointer" (click)="onRemoveItem(material, index)">
                                        <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                                                  class="size20"></svg-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mb-4 fxColumn borderRadius p12" style="background-color: #f8f8f8; height: calc(100% - 400px)">
                    <div class="fxRow fxColumnCenter">
                        <label for="form-input-subject" class="form-label">{{ 'Custom Ingredients' | translate }}</label>
                        <label *ngIf="error.materialList" for="form-input-subject" class="form-label colorDanger ml12 font12">{{ 'Please add at least one ingredient' | translate }}</label>
                    </div>
                    <div *ngIf="!isHaveCustomProduct()" class="fullHeight fxRow fxRowCenter fxColumnCenter">{{'No Data'}}</div>
                    <div *ngIf="isHaveCustomProduct()" class="fullHeight scrollY borderRadius" [class.borderDanger]="error.materialList">
                        <div *ngFor="let material of recipe.materialList; let index = index;">
                            <div *ngIf="material.itemList[0]?.isNeedValidate" class="fxRow fxColumnCenter fxRowSpaceBetween py6 borderRadius mb12" style="gap: 12px;">
                                <input [(ngModel)]="material.itemList[0].name"
                                       [class.is-invalid]="errorMaterialItem[index] && errorMaterialItem[index].name" placeholder="Name"
                                       class="form-control w35p" (input)="onInput(index ,'name')"
                                />
                                <input [(ngModel)]="material.itemList[0].qty" type="number" min="1" pattern="^[0-9]" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                                       [class.is-invalid]="errorMaterialItem[index] && errorMaterialItem[index].qty" placeholder="Amount" (input)="onInput(index ,'qty')"
                                       class="form-control w25p"
                                />
                                <select [(ngModel)]="material.itemList[0].unitOfMeasurement" [class.is-invalid]="errorMaterialItem[index] && errorMaterialItem[index].measure"
                                        (change)="onInput(index ,'measure')" class="form-control w30p" placeholder="Measure" style="appearance: auto !important;">
                                    <option *ngFor="let uom of getItemMeasure(material.itemList[0].unitOfMeasurement)" [value]="uom">{{uom}}</option>
                                </select>
                                <div class="w10p fxRow fxRowCenter fxColumnCenter">
                                    <div class="cursorPointer" (click)="onRemoveItem(material, index)">
                                        <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                                                  class="size20"></svg-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fxRow fxRowEnd gap12">
            <button class="btn btn-solid" (click)="onCancel()">
                {{ 'Cancel' | translate }}
            </button>
            <button *ngIf="(!recipe._id && recipe.status !== 'draft') || (recipe._id && recipe.status === 'draft')" class="btn btn-solid" (click)="onSaveDaft()" [disabled]="isCreating || !isChangeData() || recipe.status === 'pending'">
                {{ 'Save Draft' | translate }}
            </button>
            <button *ngIf="(!recipe._id) || (recipe._id && recipe.status === 'draft')" class="btn btn-solid" (click)="onAddRecipe()" [disabled]="isCreating || recipe?.materialList?.length <= 0 || recipe.status !== 'draft'">
                {{ 'Create' | translate }}
            </button>
            <button *ngIf="recipe._id && recipe.status !== 'draft'" class="btn btn-solid" (click)="onUpdateRecipe()" [disabled]="isCreating || recipe?.materialList?.length <= 0">
                {{ 'Update' | translate }}
            </button>
            <app-loader-overlay *ngIf="isCreating"></app-loader-overlay>
        </div>
    </div>

    <div *ngIf="!isLoaded" class="fullHeight">
        <!-- product listing section start -->
        <div class="fullHeight">
            <div class="row">
                <div class="col-3">
                    <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'width': '320px', 'border-radius': '0', height: '80vh' }"></ngx-skeleton-loader>
                </div>

                <div class="col-6 px-3">
                    <div class="row g-sm-4 g-3">
                        <app-skeleton-product-box
                                *ngFor="let product of [].constructor(4)"
                                class="col-xxl-3"
                        ></app-skeleton-product-box>
                    </div>
                </div>
                <div class="col-2">
                    <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'width': '320px', 'border-radius': '0', height: '80vh', 'padding-lef': '90px' }"></ngx-skeleton-loader>
                </div>

            </div>
        </div>
        <!-- product listing section end -->
    </div>
</div>
